import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import links from '@utils/externalLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Button = makeShortcode("Button");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "building-with-our-design-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#building-with-our-design-system",
        "aria-label": "building with our design system permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Building with our design system`}</h2>
    <p>{`We have React component libraries available covering common UI needs across all our brands.`}</p>
    <h3>{`Fable component library`}</h3>
    <p>{`Fable is our next generation component library that we use across all our Sainsbury's brands. `}</p>
    <p>{`The benefits of Fable: `}</p>
    <ul>
      <li parentName="ul">{`Built using TypeScript which makes it easy to integrate into projects`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sainsburys-tech/design-systems/blob/main/packages/fable/src/Button/src/Button.tsx#L51-L58"
        }}>{`Well-documented code with examples`}</a>{` which you can see in your editor`}</li>
      <li parentName="ul">{`Aligned with the `}<a parentName="li" {...{
          "href": "https://www.figma.com/@sainsburys"
        }}>{`Figma UI libraries`}</a>{` meaning that all designs can be quickly and accurately built`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sainsburys-tech/design-systems/blob/main/packages/fable/package.json#L52"
        }}>{`Minimal dependencies`}</a>{` so the code you ship is as small as possible`}</li>
      <li parentName="ul">{`Multi-brand component library with all the Sainsbury's brands styling included`}</li>
    </ul>
    <Section mdxType="Section">
  <Button variant="filled" element="a" href={links.fable.fableGettingStarted} className="ln-u-margin-right" mdxType="Button">
        Get access to Fable
  </Button>
    </Section>
    <p>{`Fable version one has already been released nad is ready to go, so please check out our story book and start using our components from the `}<a parentName="p" {...{
        "href": "https://sainsburys-tech.github.io/design-systems/?path=/docs/documentation-introduction--introduction"
      }}>{`design system Storybook`}</a>{`. `}</p>
    <p>{`If you can't find a component you need in our design system, you can fill in our `}<a parentName="p" {...{
        "href": "https://forms.office.com/Pages/ResponsePage.aspx?id=NNYf4bUm9EeLjJCORm6b3x0qvB_yiwJFqthyo3pg7cVUQUFPR1g2MUtUWEhJQVFLVlZGR1JJVFVKSi4u"
      }}>{`component feedback form`}</a>{` to let us know what's missing. `}</p>
    <hr></hr>
    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Luna component library" titleElement="h3" linkText="Go to Luna GitHub" linkTo="https://github.com/JSainsburyPLC/luna" border mdxType="GridListItem">
    A legacy component library used to build existing Sainsbury's experiences.
  </GridListItem>
  <GridListItem title="Bolt component library" titleElement="h3" linkText="Go to Bolt GitHub" linkTo="https://github.com/sainsburys-tech/dig-bolt-monorepo-ui" border mdxType="GridListItem">
    A legacy component library used to build existing Argos experiences.
  </GridListItem>
    </GridList>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our developers are on hand to answers questions or provide any help with your
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      